<template class="grey lighten-5">
  <div>
    <v-container fluid style="margin: 0; padding: 0;">
      <v-row dense>
        <v-col cols="12" class="text-center">
          <v-card
            color="#385F73"
            dark
          >
            <v-card-title class="text-h5 justify-center styleText">
              Qu'est-ce que Lottoz ?
            </v-card-title>
            <v-card-subtitle class="styleText">Lottoz est un logiciel d'analyse et de création de combinaisons de loterie. Depuis la création d'EuroMillions en 2004, la même combinaison n’est jamais sortie deux fois lors d’un tirage. Lottoz vous permet de générer des millions de combinaisons inédites autant de fois que vous le désirez.</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>


  export default {
    data: () => ({
      items: [
        {
          color: '#1F7087',
          src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
          title: 'Supermodel',
          artist: 'Foster the People',
        },
        {
          color: '#952175',
          src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
          title: 'Halcyon Days',
          artist: 'Ellie Goulding',
        },
      ],
    }),

    computed: {
      
    },

    methods: {
      
    },
  }
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap');

.styleText{
  font-family: 'Titillium Web', sans-serif !important;
}
</style>