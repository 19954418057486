export const httpService = {
  data(){
    return{
      //API : "http://192.168.1.30:8080/"
      //API: "http://193.134.250.48:8080/"
      API : "https://env-lotoz-9224703.jcloud-ver-jpc.ik-server.com/"
    }
  },
  methods: {
    sendRequest(url, data){
      return this.$http.post(this.API + url, data);
    },
    getRequest(url){
      return this.$http.get(this.API + url);
    }
  }
}