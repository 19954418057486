<template>
  <v-app id="inspire">
    <div>
        <!-- ======= Header ======= -->
  <header id="header" class="fixed-top d-flex align-items-center  header-transparent ">
    <div class="container d-flex align-items-center justify-content-between">

      <div class="logo">
        <h1><a href="index.html" class="lotozLogo">Lottoz</a></h1>
        <!-- Uncomment below if you prefer to use an image logo -->
        <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
      </div>

      <v-app-bar
      app
      dark
      fade-img-on-scroll
      style="background:linear-gradient(0deg, #2a2c39 0%, #33364a 100%)"
    >

      <v-spacer></v-spacer>
      <v-app-bar-title class="lotozLogo"><h3><strong><span style="color: #fd9042;font-family: 'Lobster', cursive;">L</span>ottoz</strong></h3></v-app-bar-title>
      
      <v-spacer></v-spacer>
  
    </v-app-bar>

    </div>
  </header><!-- End Header -->

  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex flex-column justify-content-end align-items-center">
    <div id="heroCarousel" data-bs-interval="5000" class="container carousel carousel-fade" data-bs-ride="carousel">

      <!-- Slide 1 -->
      <div class="carousel-item active">
        <div class="carousel-container">
          <NumGenerateur/>
        </div>
      </div>
    </div>

  </section><!-- End Hero -->

  <main id="main">

    <!-- ======= About Section ======= -->
    <section id="about" class="about">
    <svg class="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
      <defs>
        <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
      </defs>
      <g class="wave1">
        <use xlink:href="#wave-path" x="50" y="2" fill="rgba(255,255,255, .1)"></use>
      </g>
      <g class="wave2">
        <use xlink:href="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)"></use>
      </g>
      <g class="wave3">
        <use xlink:href="#wave-path" x="50" y="5" fill="#fff"></use>
      </g>
    </svg>
      <div class="container">

        <div class="section-title" data-aos="zoom-out">
          <h2 style="color: #DCDCDC;">À Propos</h2>
          <p style="color: white;">Qu'est-ce que Lottoz ?</p>
        </div>

        <div class="row content" data-aos="fade-up">
          <div class="col-lg-6">
            <p style="color:#D3D3D3">
              Lottoz est un logiciel d'analyse et de création de combinaisons de loterie. Depuis la création d'EuroMillions en 2004, la même combinaison n’est jamais sortie deux fois lors d’un tirage. Lottoz vous permet de générer des millions de combinaisons inédites autant de fois que vous le désirez.
            </p>
          </div>
        </div>

      </div>
    </section><!-- End About Section -->
    <section id="securite" class="securite">
      <div class="container">

        <div class="row content" data-aos="fade-up">
          <div class="col-lg-6">

          </div>
          <div class="col-lg-6 pt-lg-0">
            <div class="section-title" data-aos="zoom-out">
          <h2>Sécurité</h2>
          <p>Mentions Légales</p>
        </div>
            <p>
              Jouer comporte des risques. Les services proposés par Lottoz sont purement informatifs et n'affectent en rien les chances de gain.
            </p>
          </div>
        </div>

      </div>
    </section><!-- End About Section -->

    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact" style="background: linear-gradient(0deg, #2a2c39 0%, #33364a 100%);">
      <div class="container">

        <div class="section-title" data-aos="zoom-out">
          <h2 style="color: #DCDCDC;">Contact</h2>
          <p style="color: white;">Contactez nous</p>
        </div>

        <div class="row mt-5">

          <div class="col-lg-4" data-aos="fade-right" style="margin-top:35px;">
            <div>
              <div class="address">
                <i class="bi bi-geo-alt iconContact"></i>
                <h4 class="h4Contact">Adresse:</h4>
                <p class="paragraphContact">Rue du 31 Décembre 22, 1207 Genève</p>
              </div>

              <div class="email">
                <i class="bi bi-envelope iconContact"></i>
                <h4 class="h4Contact">Email:</h4>
                <p class="paragraphContact">info@o-dyssee.ch</p>
              </div>

              <div class="phone">
                <i class="bi bi-phone iconContact"></i>
                <h4 class="h4Contact">Tél.:</h4>
                <p class="paragraphContact">+41 79 520 21 68</p>
              </div>

            </div>

          </div>

          <div class="col-lg-8 mt-5 mt-lg-0" data-aos="fade-left">

            <Contact/>

          </div>

        </div>

      </div>
    </section><!-- End Contact Section -->

  </main><!-- End #main -->

  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="container">
      <h3><span style="color: #fd9042;" class="lotozLogo">L</span>ottoz</h3>
      <div class="copyright">
        &copy;Copyright <strong><span>2022</span></strong>. All Rights Reserved
      </div>
      
    </div>
  </footer><!-- End Footer -->

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
    </div>
  </v-app>
</template>


<!-- PARTIE JS -->
<script>
import NumGenerateur from './components/NumGenerateur.vue'
import Contact from './components/Contact.vue'
  export default {
    name:"Lottoz",
    data: () => ({ 
    drawer: false,
    items: [
          { title: 'EuroMillions', icon: 'mdi-star-circle', to: '/'}
        ],
        right: null,
        images: {
                icon: require('./assets/images/logo.png'),
                appBarImgBackground: require('./assets/images/app-bar-background.jpg')
                
      }
    }),
    mounted(){

    },
    components: {
      NumGenerateur,
      Contact
    },
    methods: {

    }
  }
</script>

<!-- PARTIE CSS -->
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Comforter&family=Lobster&display=swap');
.lotozLogo{
font-family: 'Lobster', cursive;
//margin-left: 200px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

@media only screen and (max-width: 770px) {
  .lotozLogo{
    font-family: 'Lobster', cursive;
    //margin-left: 100px;
  }
}

@media only screen and (max-width: 550px) {
  .lotozLogo{
    font-family: 'Lobster', cursive;
    //margin-left: 0px;
  }
}
</style>