<template class="grey lighten-5">
  <form class="styleText">
    <v-container>
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          class="text-center"
        >
          <v-alert
            dense
            prominent
            shaped
            type="success"
            v-if="successFormContact"
          >
            <div class="text-h6">
              Votre requête a été envoyée avec succès !
            </div>
          </v-alert>
          <v-alert
            dense
            prominent
            shaped
            type="error"
            v-if="errorFormContact"
          >
            <div class="text-h6">
              Une erreur est survenue, veuillez réessayer ultérieurement.
            </div>
          </v-alert>
          <v-alert
            dense
            prominent
            shaped
            type="warning"
            v-if="errorEmptyFields"
          >
            <div class="text-h6">
              Veuillez remplir entièrement le formulaire svp.
            </div>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-container >
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="6"
          class="text-center"
        >
          <v-text-field
            outlined
            :error-messages="nameErrors"
            v-model="name"
            dark
            :counter="25"
            class="rounded-0 custom-label-color"
            color="white"
            label="Votre nom"
            hide-details="true"
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
            style="margin-left:10px; margin-right: 10px; border-color: black !important;"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="text-center"
        >
          <v-text-field
            outlined
            :error-messages="lastnameErrors"
            v-model="lastname"
            dark
            :counter="25"
            label="Votre prénom"
            class="rounded-0 custom-label-color"
            color="white"
            hide-details="true"
            @input="$v.lastname.$touch()"
            @blur="$v.lastname.$touch()"
            style="margin-left:10px; margin-right: 10px;"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-container >
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="6"
          class="text-center"
        >
          <v-text-field
            :error-messages="emailErrors"
            outlined
            v-model="email"
            dark
            class="rounded-0 custom-label-color"
            color="white"
            label="Votre e-mail"
            required
            hide-details="true"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
            style="margin-left:10px; margin-right: 10px;"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="text-center"
        >
        <v-select
          outlined
          :error-messages="selectErrors"
          v-model="select"
          dark
          :items="items"
          class="rounded-0 custom-label-color"
          color="white"
          label="Choisissez un motif"
          required
          hide-details="true"
          @change="$v.select.$touch()"
          @blur="$v.select.$touch()"
          style="margin-left:10px; margin-right: 10px;"
        ></v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-container >
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          class="text-center"
        >
          <v-textarea
            :error-messages="commentsErrors"
            v-model="comments"
            label="Remarques"
            required
            dark
            class="rounded-0 custom-label-color"
            color="white"
            hide-details="true"
            @input="$v.comments.$touch()"
            @blur="$v.comments.$touch()"
            rows="2"
            outlined
            style="margin-left:10px; margin-right: 10px;"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-container>
    <v-container style="padding-top: 0px; margin-top: 0px;">
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          class="text-center"
                    style="margin-bottom: 20px;"
        >
          <v-btn
            class="mr-4 styleText"
            @click="submit"
            rounded
            color="#ef6603"
            style="color: white;"
            
          >
            Envoyer
          </v-btn>
          <v-btn
          class="styleText" 
          outlined
          rounded
          @click="clear"
          style="color: white"
          >
            Effacer
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </form>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, maxLength, email } from 'vuelidate/lib/validators'

  export default {
    mixins: [validationMixin],

    validations: {
      name: { required, maxLength: maxLength(25) },
      lastname: { required, maxLength: maxLength(25) },
      email: { required, email },
      select: { required },
      comments: { required},
      checkbox: {
        checked (val) {
          return val
        },
      },
    },

    data: () => ({
      name: '',
      lastname: '',
      email: '',
      comments: '',
      select: null,
      successFormContact: false,
      errorFormContact : false,
      errorEmptyFields: false,
      items: [
        'Signaler un bug',
        'Faire une suggestion',
        'Autre motif',
      ],
      checkbox: false,
    }),

    computed: {
      selectErrors () {
        const errors = []
        if (!this.$v.select.$dirty) return errors
        !this.$v.select.required && errors.push('Veuillez sélectionner un motif')
        return errors
      },
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.maxLength && errors.push('Votre nom ne doit pas dépasser les 25 cararctères.')
        !this.$v.name.required && errors.push('Veuillez saisir votre nom.')
        return errors
      },
      commentsErrors () {
        const errors = []
        if (!this.$v.comments.$dirty) return errors
        !this.$v.comments.required && errors.push("Veuillez nous donner plus d'informations concernant la raison de votre contact.")
        return errors
      },
      lastnameErrors () {
        const errors = []
        if (!this.$v.lastname.$dirty) return errors
        !this.$v.lastname.maxLength && errors.push('Votre prénom ne doit pas dépasser les 25 cararctères.')
        !this.$v.lastname.required && errors.push('Veuillez saisir votre prénom.')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('Votre e-mail est invalide')
        !this.$v.email.required && errors.push('Veuillez saisir votre e-mail.')
        return errors
      },
    },

    methods: {
      submit () {
        if(this.name != '' && this.lastname != '' && this.email != '' && this.select != null && this.remarques != ''){
          this.$v.$touch();
          let formToSend = {
            userName: this.name,
            userLastname: this.lastname,
            userEmail: this.email,
            purpose: this.select,
            comments: this.comments
          };
          this.$loading.show({delay:0, background: '#444'})   //delay 0ms, default is 300ms
            this.sendRequest("saveRequest", formToSend).then(response => {
              console.warn("response : ", response.status);
              if(response.status == 201 || response.status == 200){
                console.log("ENTERED ON SUCCESS");
                this.successFormContact = true;
                this.$loading.hide();
                this.clear();
                  setTimeout(() => {
                  this.successFormContact = false;
                }, 5000);
              } else {
                setTimeout(() => {
                  this.$loading.hide();
                  this.errorFormContact = true;
                  setTimeout(() => {
                  this.$loading.hide();
                  this.errorFormContact = false;
                }, 5000)
                }, 1000);
              }
              }, error => {
                setTimeout(() => {
                  this.$loading.hide();
                  this.errorFormContact = true;
                  setTimeout(() => {
                  this.$loading.hide();
                  this.errorFormContact = false;
                }, 5000)
                }, 1000);
                console.error(error);
                // error callback
              });
            console.log(formToSend);
          } else {
            setTimeout(() => {
                  this.$loading.hide();
                  this.errorEmptyFields = true;
                  setTimeout(() => {
                  this.$loading.hide();
                  this.errorEmptyFields = false;
                }, 5000)
                }, 1000);
          }
      },
      clear () {
        this.$v.$reset()
        this.name = ''
        this.lastname = ''
        this.email = ''
        this.select = null
        this.comments = ''
      },
    },
  }
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap');
  .styleText{
    font-family: 'Titillium Web', sans-serif;
  }
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: rgb(255, 255, 255);
  }

</style>

<style>

  .custom-label-color .v-label {
    color: white !important;
    opacity: 1 !important;
  }

  .v-text-field{
    color: white !important;

  }
</style>