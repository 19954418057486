<template>
<div>
<v-container fluid>
      <v-row
        no-gutters
        align="center"
        justify="center"
        style="margin-bottom:30px;"
        class="rowNumbers"
      >
        <v-col
        cols="12"
        sm="3"
        >
        </v-col>
        <v-col
        cols="12"
        sm="6"
        align="center"
        justify="center"
        >
          <v-img
            max-height="250"
            max-width="250"
            src="../assets/images/euro_millions_logo.png"
            style="margin-left: 15px;"
            class="imgEuro"
        ></v-img>
        </v-col>
        <v-col
        cols="12"
        sm="3"
        >
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        justify="center"
      >
      <v-col
        cols="12"
        sm="12"
        align="center"
        justify="center"
        style="margin-top:10px; display:contents;"
        >
        <div class="text-h6 font-weight-light font-weight-black circle">{{first}}</div>
        <div class="text-h6 font-weight-light font-weight-black circle">{{second}}</div>
        <div class="text-h6 font-weight-light font-weight-black circle">{{third}}</div>
        <div class="text-h6 font-weight-light font-weight-black circle">{{fourth}}</div>
        <div class="text-h6 font-weight-light font-weight-black circle">{{fifth}}</div>
        <div class="text-h6 font-weight-light font-weight-black star">{{sixth}}</div>
        <div class="text-h6 font-weight-light font-weight-black star">{{seventh}}</div>
        </v-col>

      </v-row>
      <v-row
        no-gutters
        align="center"
        justify="center"
        style="margin-top:20px;"
      >
      <v-col
        cols="12"
        sm="12"
        align="center"
        justify="center"
        style="display:contents;"
        >
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        justify="center"
        style="margin-top:10px;"
      >
        <v-col
        cols="12"
        sm="2"
        >
        </v-col>
        <v-col
        cols="12"
        sm="12"
        align="center"
        justify="center"
        >
      <v-dialog
      v-model="dialog"
      persistent
      width="800"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="ma-2 btnGenerate styleText"
          color="#33364a"
          style="color: white; font-weight: bold;"
          x-large
          rounded
          @click="getRandomNumbers()"
        >
          Générez une combinaison
        </v-btn>
      </template>
      <v-card>
        <v-card-text class="text-h6 text-center">
          <br>
          Génération de votre combinaison en cours.. <br>{{tc}} secondes restantes
          <!--<div v-if="dialog" class="loader" id="loader"></div>-->
        </v-card-text>
        <v-card-text>
          <v-img
            :aspect-ratio="16/9"
            src="../assets/images/villegas.gif"
          ></v-img>
        </v-card-text>
        <v-card-text class="text-h6 text-center">
          Lottoz vous remercie pour votre patience !
        </v-card-text>
      </v-card>
    </v-dialog>
        </v-col>
        <v-col
        cols="12"
        sm="2"
        >
        </v-col>
      </v-row>
    </v-container>
    <v-alert
      dense
      prominent
      shaped
      type="success"
      v-if="successNumbers"
    >
      <div class="text-h6">
        Récupération des numéros terminée avec succès !
      </div>
    </v-alert>
    <v-alert
      dense
      prominent
      shaped
      type="error"
      v-if="errorNumbers"
    >
      <div class="text-h6">
        Une erreur est survenue, veuillez réessayer ultérieurement.
      </div>
    </v-alert>
</div>
</template>

<script>
//@import url('../assets/images/villegas.GIF');
export default {
    data(){
        return{
            timerCount: "3",
            tc: 3,
            dialog: false,
            first: '1',
            second: '2',
            third: '3',
            fourth: '4',
            fifth: '5',
            sixth: '6',
            seventh: '7',
            successNumbers: false,
            errorNumbers: false,
            showLoading: false
        }
    },
    components: {
    },
    async mounted() {
    },
    methods: {
        startTimer() {
          var timer;
          timer = setInterval(() => {
            //console.log(this.tc);
            var minutes, seconds;
            minutes = ((this.tc % 3600) / 60) | 0;
            seconds = (this.tc % 60) | 0;
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            this.timerCount = minutes + ":" + seconds;
            this.tc--;
            if(this.tc < 0) {
              this.tc = 3;
              clearInterval(timer);
              this.dialog = false;
            }
          }, 1000);
        },
        getRandomNumbers(){
          this.startTimer();
          //this.$loading.show({delay:0, background: '#444'})   //delay 0ms, default is 300ms
          this.showLoading = true;
          this.getRequest("getRandom?uniqueId=lotoz63532").then(response => {
            if(response != null || response != -1 || response != undefined){
              // get status
              response.status;
              // get status text
              response.statusText;
              // load our list and getting total
              //console.log(response);
              this.first = response.body.first;
              this.second = response.body.second;
              this.third = response.body.third;
              this.fourth = response.body.fourth;
              this.fifth = response.body.fifth;
              this.sixth = response.body.sixth;
              this.seventh = response.body.seventh;
              //this.$loading.hide();
              this.showLoading = false;
              this.successNumbers = true;
                setTimeout(() => {
                //this.$loading.hide();
                this.successNumbers = false;
              }, 3000);
              setTimeout(() => {
                //this.$loading.hide();
                this.successNumbers = true;
                setTimeout(() => {
                //this.$loading.hide();
                this.successNumbers = false;
              }, 1500)
              }, 2500);
            } else {

                this.errorNumbers = true;
                setTimeout(() => {
                //this.$loading.hide();
                this.errorNumbers = false;
              }, 5000)
            }
            }, error => {
                //this.$loading.hide();
                this.showLoading = false;
                this.errorNumbers = true;
                setTimeout(() => {
                //this.$loading.hide();
                this.errorNumbers = false;
              }, 5000);
              console.log(error);
              // error callback
            });
        }
    }
};
</script>

<style scoped>
.loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
 }

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.hide-loader{
display:none;
}

.styleText{
  font-family: 'Titillium Web', sans-serif !important;
}

.circle {
    background: #fd9042;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
    justify-content: center;
    align-items: center;
    margin-left: 14px;
    font-size: 22px !important;
}
.text-star{
  color: black;
}
.star {
    background: #D4E157;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
    justify-content: center;
    align-items: center;
}
.star {
  height: 90px;
  width: 90px;
  -webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  text-align: center;
  background: #FFEB3B;
  color: black;
  margin-left: 14px;
  font-size: 22px !important;
}

@media only screen and (max-width: 1250px) {
  .loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
 }

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.hide-loader{
display:none;
}
  .circle {
      background: #fd9042;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
      display: -ms-flexbox;  /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
      display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
      justify-content: center;
      align-items: center;
  }
  .text-star{
    color: black;
  }
  .star {
      background: #D4E157;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
      display: -ms-flexbox;  /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
      display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
      justify-content: center;
      align-items: center;
  }
  .star {
    height: 80px;
    width: 80px;
    -webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    text-align: center;
    background: #FFEB3B;
    color: black;
  }
}

@media only screen and (max-width: 1030px) {
  .loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
 }

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.hide-loader{
display:none;
}
  .circle {
      background: #fd9042;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
      display: -ms-flexbox;  /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
      display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
      justify-content: center;
      align-items: center;
  }
  .text-star{
    color: black;
  }
  .star {
      background: #D4E157;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
      display: -ms-flexbox;  /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
      display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
      justify-content: center;
      align-items: center;
  }
  .star {
    height: 60px;
    width: 60px;
    -webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    text-align: center;
    background: #FFEB3B;
    color: black;
  }

  .rowNumbers{
    width: 100% !important;
  }
}

@media only screen and (max-width: 770px) {
  .circle {
      background: #fd9042;
      border-radius: 50%;
      width: 38px;
      height: 38px;
      display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
      display: -ms-flexbox;  /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
      display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
      justify-content: center;
      align-items: center;
      margin-left: 12px;
  }
  .text-star{
    color: black;
  }

  .star {
    height: 55px;
    width: 55px;
    -webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    text-align: center;
    background: #FFEB3B;
    color: black;
    font-size: 20px !important;
  }

  .btnGenerate{
    margin-top: 20px !important;
  }

  .imgEuro{
    margin-bottom: 15px !important;
  }
}

@media only screen and (max-width: 740px) {

  .loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
 }

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.hide-loader{
display:none;
}

  .circle {
      background: #fd9042;
      border-radius: 50%;
      width: 27px;
      height: 27px;
      display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
      display: -ms-flexbox;  /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
      display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
      justify-content: center;
      align-items: center;
      margin-left: 12px;
      font-size: 15px !important;
  }
  .text-star{
    color: black;
  }

  .star {
    height: 42px;
    width: 42px;
    -webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    text-align: center;
    background: #FFEB3B;
    color: black;
    font-size: 15px !important;
  }

  .btnGenerate{
    margin-top: 20px !important;
  }

  .imgEuro{
    margin-bottom: 15px !important;
  }
}

</style>