<template>
  <div>
    <div>
    <NumGenerateur/>
    </div>
    <!--<section class="aPropos">
      <APropos/>
    </section>-->
    <section>
      <QuiSommesNous/>
    </section>
    <section>
      <MentionsLegales/>
    </section>
    <section class="contact">
      <Contact/>
    </section>
    <Footer/>
    
  </div>
</template>

<script>
//import APropos from '../components/APropos.vue'
import Footer from '../components/Footer.vue'
import NumGenerateur from '../components/NumGenerateur.vue'
import Contact from '../components/Contact.vue'
import QuiSommesNous from '../components/QuiSommesNous.vue'
import MentionsLegales from '../components/MentionsLegales.vue'

export default {
  name: "Home",
  title: 'Lottoz',
  components: {
    //APropos,
    Footer,
    NumGenerateur,
    QuiSommesNous,
    Contact,
    MentionsLegales
  },
  data: () => ({

  }),
  methods: {

  },
};
</script>
