<template>
  <v-footer
    dark
    padless
    shaped
  >
    <v-card
      class="flex"
      flat
      tile
    >
      <!--<v-card-title class="teal">

        <v-spacer></v-spacer>

        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4"
          dark
          icon
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-title>-->
      

      <v-card-text class="py-2 white--text text-center styleText">
        <strong class="styleText">Lottoz @Copyrights {{ new Date().getFullYear() }} - All rights reserved</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
    }),
  }
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap');
  .styleText{
    font-family: 'Titillium Web', sans-serif;
  }
</style>