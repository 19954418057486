<template class="grey lighten-5">
  <div>
    <v-container fluid style="margin: 0; padding: 0;">
      <v-row dense>
        <v-col cols="12" class="text-center">
          <v-card
            color="#5c869c"
            dark
          >
            <v-card-title class="text-h5 justify-center styleText">
              Mentions Légales
            </v-card-title>
            <v-card-subtitle class="styleText">
              Jouer comporte des risques. Les services proposés par Lottoz sont purement informatifs et n'affectent en rien les chances de gain. 
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>


  export default {
    data: () => ({
      items: [
        {
          color: '#1F7087',
          src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
          title: 'Supermodel',
          artist: 'Foster the People',
        },
        {
          color: '#952175',
          src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
          title: 'Halcyon Days',
          artist: 'Ellie Goulding',
        },
      ],
    }),

    computed: {
      
    },

    methods: {
      
    },
  }
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap');

.styleText{
  font-family: 'Titillium Web', sans-serif !important;
}
</style>